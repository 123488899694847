import { createAction } from "@reduxjs/toolkit";

export const actionLogin = createAction(
  "auth/login",
  function prepare({
    accessToken,
    email,
    refreshToken,
    roles,
    expires,
    userId,
  }) {
    return {
      payload: {
        accessToken,
        email,
        refreshToken,
        roles,
        expires,
        userId,
      },
    };
  }
);

export const actionMe = createAction(
  "auth/me",
  function prepare({
    accessToken,
    email,
    refreshToken,
    roles,
    expires,
    userId,
  }) {
    return {
      payload: {
        accessToken,
        email,
        refreshToken,
        roles,
        expires,
        userId,
      },
    };
  }
);

export const actionLogout = createAction("auth/logout");
