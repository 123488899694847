import React, { lazy, Suspense } from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import { Toaster } from "react-hot-toast";
import { store, persistor } from "./store/store";
import { PersistGate } from "redux-persist/integration/react";
import { Provider } from "react-redux";
import Spinner from "./components/atoms/spinner/Spinner.atom";
import "./styles/index.scss";
import "./styles/index.css"

const LazyApp = lazy(() => import("./App"));

ReactDOM.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <React.StrictMode>
        <BrowserRouter>
          <Suspense fallback={<Spinner />}>
            <LazyApp />
            <Toaster />
          </Suspense>
        </BrowserRouter>
      </React.StrictMode>
    </PersistGate>
  </Provider>,
  document.getElementById("root")
);


